var disableScroll = false;
$(document).ready(function() {
    $('body').formplate();

    // Class List
    $('.days-list').slick({
        infinite: false,
        slide: 'li',
        slidesToShow: 7,
        responsive: [{
                breakpoint: 640,
                settings: {
                    slidesToShow: 5,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    });



    // Mobile Hover
    var mobileHover = function() {
        $('*').on('touchstart', function() {
            $(this).trigger('hover');
        }).on('touchend', function() {
            $(this).trigger('hover');
        });
    };
    mobileHover();



    // Resize
    function on_resize() {
        $('body').removeClass('out');
        $('.primary-nav').removeClass('in');
        $('.menu-overlay').hide();
        disableScroll = false;
    }

    $(window).resize(function() {
        if ($('body').hasClass('out')) {
            on_resize();
        }
    });

    on_resize();



    // Mobile Menu
    $('.mobile-menu').click(function() {
        toggle_mobile_menu();
        disableScroll = true;
    });

    $('.menu-overlay').click(function() {
        if ($('.primary-nav').hasClass('in')) {
            toggle_mobile_menu();
            disableScroll = false;
        }
    });

    document.ontouchmove = function(e) {
        if (disableScroll) {
            e.preventDefault();
        }
    }

    function toggle_mobile_menu() {
        $('body').toggleClass('out');
        $('.primary-nav').toggleClass('in');
        $('.menu-overlay').toggle();
        $('.menu-toggle-hide').toggle();
        $('.menu-toggle-shown').toggle();
        $('body').toggleClass('stop-scrolling');
    }

});


function changeLocation(locate, branch_name, address) {
    locate = locate.split(',');
    var studio = { lat: parseFloat(locate[0]), lng: parseFloat(locate[1]) };
    $(".address").text(address);
    $("#google-map-modal").unbind("shown.bs.modal");
    $('#google-map-modal').on('shown.bs.modal', function() {
        var map = new google.maps.Map(document.getElementById('googleMapBody'), {
            scaleControl: true,
            center: studio,
            zoom: 16
        });

        var infowindow = new google.maps.InfoWindow;
        infowindow.setContent('<b>' + branch_name + '</b>');

        var marker = new google.maps.Marker({ map: map, position: studio });
        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
    });
    $('#google-map-modal').modal('show');
}