$(function () {
    if ($('.login-page').length) {
        var initLoginWay = $('.login-box input[name="login_way"]:checked').val();
        checkDisabledLoginWay(initLoginWay);
        $('.login-' + initLoginWay).removeClass('hidden').siblings('.login-area').addClass('hidden');
        $('.login-box input[name="login_way"]').on('change', function (e) {
            $('.login-' + $(this).val()).removeClass('hidden').siblings('.login-area').addClass('hidden').find('input,select');
            checkDisabledLoginWay($(this).val());
        });
    }
});

function checkDisabledLoginWay(loginWay) {
    var $countryCode = $('select[name="country_code"]'),
        $phone = $('input[name="phone"]'),
        $email = $('input[name="email"]');
    if (loginWay == 'email') {
        $countryCode.attr('disabled', true);
        $phone.attr('disabled', true);
        $email.attr('disabled', false);
        $(".login-box #login-phone").next().removeClass('hidden');
        $(".login-box #login-email").next().addClass('hidden');
    } else {
        $email.attr('disabled', true);
        $countryCode.attr('disabled', false);
        $phone.attr('disabled', false);
        $(".login-box #login-phone").next().addClass('hidden');
        $(".login-box #login-email").next().removeClass('hidden');
    }
}